export const expoState = {
  PREPARE: "PREPARE",
  OPENED: "OPENED",
  ENDED: "ENDED",
};

export const expoStateText = {
  PREPARE: "V přípravě",
  OPENED: "Zveřejněná",
  ENDED: "Ukončená",
};
