export enum GameQuizAnswerEnum {
  SIMPLE_CHOICE = "SIMPLE_CHOICE",
  MULTIPLE_CHOICE = "MULTIPLE_CHOICE",
}

export enum GameQuizEnum {
  ONLY_TEXT = "ONLY_TEXT",
  ONLY_IMAGES = "ONLY_IMAGES",
  TEXT_IMAGES = "TEXT_IMAGES",
}

export enum GameQuizTextDisplayEnum {
  QUIZ_TEXT_IMMEDIATELY = "QUIZ_TEXT_IMMEDIATELY",
  QUIZ_TEXT_AFTER_EVALUATION = "QUIZ_TEXT_AFTER_EVALUATION",
}
