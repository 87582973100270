export enum ImageChangeAnimationEnum {
  HORIZONTAL = "HORIZONTAL",
  VERTICAL = "VERTICAL",
  GRADUAL_TRANSITION = "GRADUAL_TRANSITION",
  FADE_IN_OUT_TWO_IMAGES = "FADE_IN_OUT_TWO_IMAGES",
}

export enum ImageChangeRodPositionEnum {
  ZERO = "0",
  QUARTER_ONE = "0.25",
  HALF_ONE = "0.5",
  THREE_QUARTER_ONE = "0.75",
  ONE = "1",
}

export enum ImageChangeGradualTransitionBeginPositionEnum {
  VERTICAL_TOP_TO_BOTTOM = "VERTICAL_TOP_TO_BOTTOM",
  VERTICAL_BOTTOM_TO_TOP = "VERTICAL_BOTTOM_TO_TOP",
  HORIZONTAL_LEFT_TO_RIGHT = "HORIZONTAL_LEFT_TO_RIGHT",
  HORIZONTAL_RIGHT_TO_LEFT = "HORIZONTAL_RIGHT_TO_LEFT",
}
